import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El carpòfor és subglobós o un poc esclafat, de 3,5 a 7 cm de diàmetre que presenta un pseudopeu molt desenvolupat i amb nombrosos rizomorfs que retenen restes de terra. El peridi és fràgil, marró, amb la capa externa que pot ser llisa o clivellada en forma d’esquames fines i irregulars. La gleva és de color blau violaci de jove, després torna en una mena de pols marró. Té olor desagradable i les espores surten per una ruptura a la part superior i són de color marró intens en massa, esfèriques, cobertes de petites �espines�, de 8-11 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      